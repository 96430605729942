import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Skeleton, Typography } from '@mui/material';
import axios from 'axios';
import { format, isAfter, isBefore, isWithinInterval } from 'date-fns';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { MeetingAppointment } from '../../../../../interfaces/interfaces';
import { createDisplayLink, imageKitLoader } from '../../../../../services/helper';

export const AppointmentCard = ({ appointmentId }: { appointmentId?: string | undefined }) => {
  const router = useRouter();
  const [appointment, setAppointment] = useState<MeetingAppointment>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<'upcoming' | 'live' | 'past'>();

  const { t } = useTranslation(['eventhome', 'appointment']);

  async function fetchAppointment() {
    if (!appointmentId) return;
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/meeting/appointment/get/${appointmentId}`);
      setAppointment(data.data);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  function calculateStatus() {
    if (!appointment) return;
    if (isBefore(new Date(), new Date(appointment.datetime_start))) return setStatus('upcoming');
    else if (isWithinInterval(new Date(), { start: new Date(appointment.datetime_start), end: new Date(appointment.datetime_end) })) return setStatus('live');
    else if (isAfter(new Date(), new Date(appointment.datetime_start))) return setStatus('past');
  }

  useEffect(() => {
    calculateStatus();
  }, [appointment]);

  useEffect(() => {
    fetchAppointment();
  }, [appointmentId]);

  const countdownRenderer = ({ days, hours, minutes, seconds }: { days: number; hours: number; minutes: number; seconds: number }) => {
    return (
      <span>
        {days} {t('appointment:days')} {hours} {t('appointment:hours')} {minutes} {t('appointment:minutes')} {seconds} {t('appointment:seconds')}
      </span>
    );
  };

  if (!appointmentId) return <></>;

  return (
    <Card>
      <CardHeader
        avatar={
          isLoading && !appointment ? (
            <Skeleton animation="wave" variant="circular" width={40} height={40} />
          ) : (
            <Avatar
              aria-label="meetingpartner"
              src={createDisplayLink(appointment?.meetingroom.image, undefined, undefined, undefined, undefined, undefined, true)}>
              {/* appointment && appointment.meetingroom.contactperson.charAt(0)?.toUpperCase() */}
            </Avatar>
          )
        }
        title={!isLoading && appointment ? appointment.meetingroom.contactperson : <Skeleton animation="wave" height={10} width="80%" />}
        subheader={
          !isLoading && appointment ? (
            <>
              {format(new Date(appointment.datetime_start), 'dd.MM.yyyy')} {t('eventhome:from')} {format(new Date(appointment.datetime_start), 'HH:mm')}{' '}
              {t('account:to')}
              {` ${format(new Date(appointment.datetime_end), 'HH:mm')}`}{' '}
            </>
          ) : null
        }
      />
      {isLoading && !appointment && <Skeleton animation="wave" height={150} width="100%" style={{ marginBottom: 6 }} />}
      {appointment && appointment.meetingroom.booth.headerImages && appointment.meetingroom.booth.headerImages.length > 0 && (
        <div className="w-full mb-3" style={{ height: '150px' }}>
          <Image
            alt="header image"
            loader={imageKitLoader}
            layout="responsive"
            width={800}
            height={150}
            objectFit="cover"
            src={appointment.meetingroom.booth.headerImages[0]}
          />
        </div>
      )}
      <CardContent>
        {isLoading && !appointment ? (
          <>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" />
          </>
        ) : (
          <>
            <Typography variant="h5" gutterBottom>
              {t('appointment:your-appointment')}
            </Typography>
            {status === 'upcoming' && (
              <>
                {t('appointment:appointment-upcoming')}{' '}
                <Countdown date={new Date(appointment!.datetime_start).getTime()} renderer={countdownRenderer} onComplete={calculateStatus} />
              </>
            )}
            {status === 'live' && t('appointment:appointment-live')}
            {status === 'past' && t('appointment:appointment-past')}
          </>
        )}
      </CardContent>
      <CardActions>
        {!isLoading && appointment ? (
          <Button
            disabled={status !== 'live'}
            variant="outlined"
            fullWidth
            className={status === 'live' ? 'animate-pulse' : ''}
            style={{
              outline: 'none',
              ...(status === 'live'
                ? {
                    backgroundColor: appointment?.meetingroom?.booth?.event?.template?.mainColor,
                    color: appointment?.meetingroom?.booth?.event?.template?.mainButtonTextColor,
                  }
                : {}),
            }}
            onClick={() => router.push(`/videochat/${appointment!.meetingroom?._id}?appointment=${appointment!._id}`)}>
            <span>{t('eventhome:join-now')}</span>
          </Button>
        ) : null}
      </CardActions>
    </Card>
  );
};
