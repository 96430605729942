import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import {
  setAudioInputDevice,
  setAudioInputDevices,
  setAudioOutputDevice,
  setAudioOutputDevices,
  setDeviceConfStream,
  setVideoInputDevice,
  setVideoInputDevices,
} from '../../../reduxToolkit/slices/deviceSlice';
import { useSelector } from '../../../reduxToolkit/typedRedux';

export function useDevices() {
  const dispatch = useDispatch();
  const { t } = useTranslation('meeting');

  const videoInputDevice = useSelector(({ device }) => device.videoInputDevice);
  const audioInputDevice = useSelector(({ device }) => device.audioInputDevice); // Even though unused, this selector is causing the component to rerender on device change
  const audioOutputDevice = useSelector(({ device }) => device.audioOutputDevice);
  const allAudioInputDevices = useSelector(({ device }) => device.allAudioInputDevices); // Even though unused, this selector is causing the component to rerender on device change
  const allAudioOutputDevices = useSelector(({ device }) => device.allAdioOutputDevices);
  const allVideoInputDevices = useSelector(({ device }) => device.allVideoInputDevices); // Even though unused, this selector is causing the component to rerender on device change

  const defaultDeviceStates = useSelector(({ device }) => device.defaultDeviceStates);
  const stream = useSelector(({ device }) => device.deviceConfStream);

  function getFromStorage(): { video: string; audio: string; audioOutput: string } {
    return JSON.parse(localStorage.getItem('devices') || JSON.stringify({ video: '', audio: '', audioOutput: '' }));
  }

  async function getDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices;
  }

  /// This function checks whether the user has granted device access without requesting the media stream
  /// Requesting the media stream another time might lead to a permanently turned on web cam light
  async function checkPermissionWithoutRequest() {
    const devices = await getDevices();
    // If the browser has not been granted access to devices, the deviceId is ''
    const deviceWithLabel = devices.find((i) => i.label.length > 0);
    if (deviceWithLabel) {
      return true;
    }
    return false;
  }

  function _filterDevices(kind: 'audioinput' | 'videoinput' | 'audiooutput', devices: MediaDeviceInfo[]): MediaDeviceInfo[] {
    return devices.filter((device: any) => device.kind === kind && device.label !== '');
  }

  async function selectDevice(device: MediaDeviceInfo) {
    const selectedDevices = getFromStorage();

    if (device.kind === 'audioinput') {
      try {
        localStorage.setItem('devices', JSON.stringify({ video: selectedDevices.video, audio: device.deviceId, audioOutput: selectedDevices.audioOutput }));
        dispatch(setAudioInputDevice(device));
      } catch (err) {
        toast(t('errors.audio-device-loading-error'));
      }
    } else if (device.kind === 'audiooutput') {
      dispatch(setAudioOutputDevice(device));
      localStorage.setItem('devices', JSON.stringify({ video: selectedDevices.video, audio: selectedDevices.audio, audioOutput: device.deviceId }));
    } else {
      //Error handling directly in publisher
      dispatch(setVideoInputDevice(device));
      localStorage.setItem('devices', JSON.stringify({ video: device.deviceId, audio: selectedDevices.audio, audioOutput: selectedDevices.audioOutput }));
    }
  }

  async function refresh() {
    const allDevices = await getDevices();
    const audioInputs = _filterDevices('audioinput', allDevices);
    const videoInputs = _filterDevices('videoinput', allDevices);
    const audioOutputs = _filterDevices('audiooutput', allDevices);

    dispatch(setAudioInputDevices(audioInputs));
    dispatch(setVideoInputDevices(videoInputs));
    dispatch(setAudioOutputDevices(audioOutputs));
  }

  function setInitials() {
    const storedDevices = getFromStorage();

    const matchedAudio = allAudioInputDevices?.filter((i) => i.deviceId === storedDevices.audio && i.label !== '');
    const validAudioDevice = allAudioInputDevices?.find((i) => i.label !== '');

    if (matchedAudio && matchedAudio[0] !== undefined) {
      dispatch(setAudioInputDevice(matchedAudio[0]));
    } else if (allAudioInputDevices && allAudioInputDevices.length > 0 && !audioInputDevice) {
      dispatch(setAudioInputDevice(allAudioInputDevices[0]));
    } else if (validAudioDevice && audioInputDevice && audioInputDevice?.label === '') {
      dispatch(setAudioInputDevice(validAudioDevice));
    } else {
      dispatch(setAudioInputDevice(audioInputDevice));
    }

    const matchedVideo = allVideoInputDevices?.filter((i) => i.deviceId === storedDevices.video && i.label !== '');
    const validVideoDevice = allVideoInputDevices?.find((i) => i.label !== '');
    if (matchedVideo && matchedVideo[0] !== undefined) {
      dispatch(setVideoInputDevice(matchedVideo[0]));
    } else if (allVideoInputDevices && allVideoInputDevices.length > 0 && validVideoDevice && !videoInputDevice) {
      dispatch(setVideoInputDevice(validVideoDevice));
    } else {
      dispatch(setVideoInputDevice(videoInputDevice));
    }

    const matchedAudioOutput = allAudioOutputDevices?.filter((i) => i.deviceId === storedDevices.audioOutput && i.label !== '');
    const validAudioOutput = allAudioOutputDevices?.find((i) => i.label !== '');
    if (matchedAudioOutput && matchedAudioOutput[0] !== undefined) {
      dispatch(setAudioOutputDevice(matchedAudioOutput[0]));
    } else if (allAudioOutputDevices && allAudioOutputDevices.length > 0 && !audioOutputDevice) {
      dispatch(setAudioOutputDevice(allAudioOutputDevices[0]));
    } else if (validAudioOutput && audioOutputDevice && audioOutputDevice?.label === '') {
      dispatch(setAudioOutputDevice(validAudioOutput));
    } else {
      dispatch(setAudioOutputDevice(audioOutputDevice));
    }
  }

  async function requestDevices(video?: any, audio?: any) {
    const constraints = {
      audio: audio ?? (!audioInputDevice || audioInputDevice?.deviceId === null) ? true : { deviceId: audioInputDevice?.deviceId },
      video:
        video === false
          ? false
          : video
          ? { ...video, width: { ideal: 1920 }, height: { ideal: 1080 } }
          : !videoInputDevice || videoInputDevice?.deviceId === null
          ? true
          : {
              deviceId: videoInputDevice?.deviceId,
              width: { ideal: 1920 },
              height: { ideal: 1080 },
            },
    };

    return await navigator.mediaDevices.getUserMedia(constraints);
  }

  function getVideoParameterForMediaRequest() {
    const storedDevices = getFromStorage();

    if (defaultDeviceStates.camOn) {
      if (videoInputDevice) {
        return { deviceId: videoInputDevice.deviceId };
      }
      if (storedDevices.video !== '' && storedDevices.video) {
        return { deviceId: storedDevices.video };
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  function getAudioParameterForMediaRequest() {
    if (defaultDeviceStates.micOn) {
      if (audioInputDevice) {
        return { deviceId: audioInputDevice.deviceId };
      }
      return true;
    } else {
      return false;
    }
  }

  function stopMediaRequest() {
    stream?.getTracks().forEach((i) => i.stop());
    dispatch(setDeviceConfStream(undefined));
  }

  return {
    refresh,
    selectDevice,
    setInitials,
    requestDevices,
    checkPermissionWithoutRequest,
    getDevices,
    getFromStorage,
    getAudioParameterForMediaRequest,
    getVideoParameterForMediaRequest,
    stopMediaRequest,
  };
}
