import { faChevronRight, faPlusCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import React from 'react';
import tinycolor from 'tinycolor2';
import { EventInterface } from '../../interfaces/interfaces';

interface VyButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  success?: boolean;
  ghost?: boolean;
  warn?: boolean;
  error?: boolean;
  compact?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  event?: EventInterface | null;
  disabled?: boolean;
  height?: number;
  showLoadingIcon?: boolean;
  loadingText?: string;
  className?: string;
  onClick?: () => void;
  color?: string;
  useSecondaryColor?: boolean;
  isAddButton?: boolean;
  isSaveButton?: boolean;
  isNextButton?: boolean;
  textColor?: string;
}

const VyButton = (props: VyButtonProps) => {
  const {
    success,
    ghost,
    warn,
    error,
    compact,
    startIcon,
    endIcon,
    event,
    disabled,
    height,
    showLoadingIcon,
    loadingText,
    className,
    onClick,
    color,
    useSecondaryColor,
    textColor,
    isAddButton,
    isSaveButton,
    isNextButton,
    ...otherProps
  } = props;

  const startIconElement = isSaveButton ? <FontAwesomeIcon icon={faSave} /> : isAddButton ? <FontAwesomeIcon icon={faPlusCircle} /> : startIcon;

  return (
    <>
      <button
        type="button"
        {...otherProps}
        disabled={disabled}
        className={`${
          compact ? 'p-2' : 'p-3'
        } font-bold rounded-md text-white focus:outline-none transition duration-100 shadow-sm hover:shadow-none flex flex-row justify-center align-middle items-center text-sm ${
          className ?? ''
        }`}
        style={{ ...props.style, fontFamily: event?.template.customFont ?? 'Gilroy !important' }}
        onClick={onClick}>
        {(showLoadingIcon || startIconElement) && (
          <div className="flex items-center mr-2">{!showLoadingIcon ? startIconElement : <CircularProgress size={15} color="inherit" />}</div>
        )}
        {showLoadingIcon && loadingText ? loadingText : props.children}
        {(endIcon || isNextButton) && <div className="flex items-center ml-2">{endIcon ?? <FontAwesomeIcon icon={faChevronRight} />}</div>}
      </button>
      <style jsx>{`
        button {
          ${height ? `height: ${height}px; ` : ''}
          min-width: ${compact ? '' : '8rem'};
          color: ${textColor
            ? textColor
            : event && useSecondaryColor
            ? event.template.complementButtonTextColor
            : event
            ? event.template.mainButtonTextColor
            : success
            ? 'var(--vystem-success-text)'
            : warn
            ? 'var(--vystem-warn-text)'
            : error
            ? 'var(--vystem-danger-text)'
            : ghost
            ? 'black'
            : 'white'};
          background-color: ${color
            ? color
            : event && useSecondaryColor
            ? event.template.complementColor
            : event
            ? event?.template.mainColor
            : success
            ? 'var(--vystem-success)'
            : warn
            ? 'var(--vystem-warn)'
            : error
            ? 'var(--vystem-danger)'
            : ghost
            ? 'rgb(203 213 225)'
            : 'var(--vystem-blue)'};

          &:disabled {
            background-color: ${event ? tinycolor(event.template.mainColor).desaturate(70).toString() : '#919191'};
            cursor: auto;
            opacity: 0.5;
          }

          &:hover:not([disabled]) {
            ${disabled !== true ? 'cursor: pointer;' : ''}
            background-color: ${color
              ? tinycolor(color).darken(20).toString()
              : event
              ? tinycolor(event.template.mainColor).darken(20).toString()
              : success
              ? 'var(--vystem-success-dark)'
              : warn
              ? 'var(--vystem-warn-dark)'
              : error
              ? 'var(--vystem-danger-dark)'
              : ghost
              ? 'rgb(51 65 85)'
              : 'var(--vystem-blue-dark)'};
            color: ${textColor
              ? textColor
              : event
              ? event.template.mainButtonTextColor
              : success
              ? 'var(--vystem-success-text)'
              : warn
              ? 'var(--vystem-warn-text)'
              : error
              ? 'var(--vystem-danger-text)'
              : ghost
              ? 'white'
              : 'white'};
          }
        }
      `}</style>
    </>
  );
};

export default VyButton;
