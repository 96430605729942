import { useRouter } from 'next/router';
import React from 'react';
import { usePermission } from '../../hooks/usePermission';
import { useSelector } from '../../reduxToolkit/typedRedux';
import version from '../../version';
import Link from 'next/link';
import dynamic from 'next/dynamic';

const DynamicDefaultFooter = dynamic(() => import('./DefaultFooter'), {
  ssr: false,
});
const DynamicMBWFooter = dynamic(() => import('../event/templates/MBW/MBWFooter'), {
  ssr: false,
});

const VyFooterHorizontal = () => {
  const router = useRouter();
  const event = useSelector((state) => state.common.event ?? state.admin.event);
  const user = useSelector((state) => state.common.user);
  const pagedata = useSelector((state) => state.common.pagebuilder.currentpagedata ?? state.common.pagedata);
  const hideFooter =
    router.asPath?.includes('videochat') ||
    event?.template.uiTemplate === 'vystem-zukunftsrat' ||
    event?.template.uiTemplate === 'vbw-additive-manufacturing' ||
    router.pathname.includes('output') ||
    router.pathname.includes('/hybrid') ||
    router.pathname.includes('/meetingdetails');

  const { isEventHost } = usePermission();

  return (
    <>
      {!hideFooter && (
        <footer className="bg-white text-slate-500">
          {(!event ||
            event?.organization?.supportedPagebuilderThemes?.length < 2 ||
            !pagedata ||
            !pagedata?.template?.isCustomFooter ||
            router.pathname.includes('/account')) && <DynamicDefaultFooter />}
          {!router.pathname.includes('/account') &&
            event &&
            event?.organization?.supportedPagebuilderThemes?.length > 1 &&
            pagedata &&
            pagedata?.template?.isCustomFooter && <>{event?.organization?.supportedPagebuilderThemes?.includes('mbw') && <DynamicMBWFooter />}</>}
          <div className={`w-full p-2 flex justify-center overflow-x-auto bg-[#001c38] ${isEventHost(user, event) ? 'pl-[5rem]' : 'pl-2'}`}>
            <div className="justify-start w-full transition duration-100 hover:text-slate-400" style={{ maxWidth: '1333px' }}>
              <span>
                powered by&nbsp;
                <Link target="_blank" href="https://vystem.io?utm_source=vystem.io&utm_medium=frontend&utm_content=footer" className="no-underline">
                  vystem.io
                </Link>
                &nbsp;
                <Link href="https://vystem.notion.site/Product-Changelog-c328bf4cb73341538901dec33075a9a7" target="_blank" className="text-xs no-underline">
                  v{version}
                </Link>
              </span>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default VyFooterHorizontal;
