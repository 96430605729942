import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import VyButton from './VyButton';
import VyModal from './VyModal';

interface VyConfirmProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onConfirm?: () => void;
  onDeny?: () => void;
  onClose?: () => void;
  onConditionalConfirm?: () => Promise<boolean>;
  title?: string;
  message?: string;
  confirmText?: string;
  warningText?: string;
  denyText?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  defaultOpen?: boolean;
  skipConfirmation?: boolean;
  content?: React.ReactNode;
}

export const VyConfirm = ({
  onClick,
  onConfirm,
  disabled,
  onDeny,
  onClose,
  onConditionalConfirm,
  children,
  confirmText,
  denyText,
  title,
  message,
  warningText,
  className,
  defaultOpen = false,
  skipConfirmation = false,
  content,
}: VyConfirmProps) => {
  const [open, setOpen] = useState(defaultOpen);
  const { t } = useTranslation(['common']);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) {
      // do nothing on disabled
    } else if (onConditionalConfirm) {
      const requireConfirm = await onConditionalConfirm();
      if (requireConfirm) {
        setOpen(true);
        onClick?.(e);
      } else {
        onConfirm?.();
        return;
      }
    } else {
      e.stopPropagation();
      if (skipConfirmation) {
        onConfirm?.();
        return;
      }
      setOpen(true);
      onClick?.(e);
    }
  };

  return (
    <>
      <VyModal open={open} onClose={handleClose}>
        {(onClose) => (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <p className="text-xl font-bold text-left text-slate-900">{title || t('confirm-default-title')}</p>
              <p className="text-left text-slate-600">{message || t('confirm-default-text')}</p>
              {warningText && <Alert severity="warning">{warningText}</Alert>}
            </div>
            {content && <div>{content}</div>}
            <div className="flex flex-col gap-2 justify-between sm:flex-row">
              <VyButton
                className="w-full"
                startIcon={<FontAwesomeIcon icon={faCheck} />}
                onClick={() => {
                  onClose();
                  onConfirm?.();
                }}>
                {confirmText || t('confirm')}
              </VyButton>
              <VyButton
                ghost
                className="w-full"
                onClick={() => {
                  onClose();
                  onDeny?.();
                }}>
                {denyText || t('deny')}
              </VyButton>
            </div>
          </div>
        )}
      </VyModal>
      <div
        className={`${disabled ? '' : 'cursor-pointer'} ${className ? className : ''}`}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          await handleClick(e);
        }}>
        {children}
      </div>
    </>
  );
};
