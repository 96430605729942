import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './slices/adminSlice';
import commonReducer from './slices/commonSlice';
import deviceSlice from './slices/deviceSlice';
import vbwReducer from './slices/vbwSlice';
import videoReducer from './slices/videoSlice';
import functionSlice from './slices/functionSlice';
import chatSlice from './slices/chatSlice';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    admin: adminReducer,
    video: videoReducer,
    vbw: vbwReducer,
    device: deviceSlice,
    function: functionSlice,
    chat: chatSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
  devTools: true,
});

// Get the type of our store variable
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
