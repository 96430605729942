import { TFunction } from 'next-i18next';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

type AdditionalOptions = Omit<RegisterOptions, 'pattern' | 'required'>;

const validationBuilder = (t: TFunction) => {
  const baseRules = {
    youtubeVideo: {
      pattern: { value: /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/, message: t('validations:youtube-url') },
    },
    customVideo: {
      pattern: {
        value: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)+/,
        message: t('validations:video-url'),
      },
    },
    vimeoVideo: {
      pattern: {
        value: /^(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/,
        message: t('validations:vimeo-url'),
      },
    },
    iframe: {
      pattern: {
        value: /^<iframe.*>.*<\/.*iframe>$/,
        message: t('validations:iframe'),
      },
    },
    emailForm: {
      pattern: {
        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        message: t('validations:email'),
      },
    },
    urlWithoutProtocol: {
      pattern: {
        value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
        message: t('validations:url'),
      },
    },
  };

  const ruleGetter = (rule: keyof typeof baseRules, required?: boolean, additionalOptions?: AdditionalOptions) => {
    let baseRule = {
      ...baseRules[rule],
      ...(required && { required: { value: true, message: t('form:fieldrequired') } }),
    };
    if (additionalOptions) baseRule = { ...baseRule, ...additionalOptions };
    return baseRule;
  };

  return ruleGetter;
};

export default validationBuilder;
