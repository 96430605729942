import { useRouter } from 'next/router';
import { setIntercom, setShowIntercomButton } from '../reduxToolkit/slices/commonSlice';
import { useDispatch, useSelector } from '../reduxToolkit/typedRedux';
import Intercom, { shutdown as shutdownIntercom, update as updateIntercom, show as showIntercom, showNewMessage } from '@intercom/messenger-js-sdk';
import { createDisplayLink } from '../services/helper';
import { IntercomSettings } from '@intercom/messenger-js-sdk/dist/types';

function useIntercom() {
  const event = useSelector((state) => state.common.event ?? state.admin.event);
  const user = useSelector((state) => state.common.user);
  const intercomId = useSelector((state) => state.common.intercomId);

  const router = useRouter();

  const isAccount = router.asPath?.includes('/account');
  const defaultIntercomId = process.env.defaultIntercomId ?? 'cbam6m39';

  const dispatch = useDispatch();

  /**
   * Sets the showIntercomButton on the common slice to true
   */
  const showButton = () => dispatch(setShowIntercomButton(true));

  /**
   * Sets the showIntercomButton on the common slice to false
   */
  const hideButton = () => dispatch(setShowIntercomButton(false));

  /**
   * Sets the intercomId on the common slice
   * @param id - the intercomId to set
   */
  const setIntercomId = (id: string) => dispatch(setIntercom(id));

  function getIntercomConfig(): IntercomSettings {
    const appId = isAccount ? defaultIntercomId : intercomId ?? defaultIntercomId;
    return {
      app_id: appId,
      api_base: 'https://api-iam.eu.intercom.com',
      name: user?.name,
      email: user?.email,
      user_id: user?._id,
      ...(user?.avatar && {
        avatar: { type: 'avatar', image_url: createDisplayLink(typeof user?.avatar == 'string' ? user?.avatar : user?.avatar?._id) },
      }),
      orgid: user?.organization?._id,
      orgname: user?.organization?.name,
      eventname: event?.name,
      eventlink: event?.slug && `${process.env.baseURL}/event/${event?.slug}`,
      eventid: event?._id,
    };
  }

  /**
   * Sets up the intercom with default settings
   */
  function setup() {
    if ((window as any)?.Intercom) {
      update();
    } else {
      const intercomConfig = getIntercomConfig();
      Intercom(intercomConfig);
    }
  }

  /**
   * Updates the intercom with the given config or refreshes the intercom with default settings
   * @param updateConfig - the config to update
   */
  function update(updateConfig?: Partial<IntercomSettings>) {
    if (!(window as any)?.Intercom) return;
    const intercomConfig = getIntercomConfig();
    updateIntercom({ ...intercomConfig, ...updateConfig });
  }

  /**
   * Shuts down the intercom
   */
  function shutdown() {
    if (!(window as any)?.Intercom) return;
    shutdownIntercom();
  }

  /**
   * Toggles the intercom button directly (This has no effect on the state that is set in the common slice)
   * @param show - whether to show the intercom button
   */
  function toggleIntercomButton(show: boolean) {
    update({ hide_default_launcher: !show });
  }

  /**
   * Opens the Intercom messenger
   */
  function show() {
    if (!(window as any)?.Intercom) return;
    showIntercom();
  }

  /**
   * Opens the Intercom messenger with a pre-typed message
   * @param message - the message to pre-populate in the messenger
   */
  function showWithMessage(message: string) {
    if (!(window as any)?.Intercom) return;
    showIntercom();
    showNewMessage(message);
  }

  return {
    showButton,
    hideButton,
    setup,
    update,
    shutdown,
    setIntercomId,
    toggleIntercomButton,
    show,
    showWithMessage,
  };
}

export default useIntercom;
