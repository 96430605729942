import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/dist/client/router';
import React from 'react';
import { EventInterface } from '../../interfaces/interfaces';
import { createDisplayLink, extractHTMLContent, formatUTCDate } from '../../services/helper';

interface VySEOProps {
  title: string | { de: string; en: string };
  description?: string;
  noindex?: boolean;
  image?: any;
  vystemEvent?: EventInterface; // this is not loaded from Redux because we need this data being supplied from server side
}

/**
 * this component is meant to be the first component on each page. It adds necessary SEO tags like site title and OpenGraph Tags
 *
 * With the component it is not needed to add a manual <Head><title>demo</title></Head> anymore
 */
const VySEO = ({ title, description, noindex = true, image, vystemEvent }: VySEOProps) => {
  const { i18n } = useTranslation();
  const router = useRouter();
  const cleanDescription: string | undefined = description && extractHTMLContent(description);
  description = description && description.length > 197 ? description?.substring(0, 197) + '...' : description;
  let fullTitle: string;

  const shouldIndex = vystemEvent?.settings?.allowSearchEngineIndex ? vystemEvent?.settings?.allowSearchEngineIndex === true : noindex;

  if (typeof title == 'undefined') {
    fullTitle = 'unnamed';
  } else if (typeof title == 'string') {
    fullTitle = title;
  } else if (i18n.language === 'de') {
    fullTitle = title.de;
  } else {
    fullTitle = title.en;
  }
  title = title + ' | vystem.io';

  const faviconUrl = vystemEvent?.template?.favicon && createDisplayLink(vystemEvent?.template?.favicon, true, 64, 64, 5);

  return (
    <>
      <NextSeo
        title={fullTitle}
        description={cleanDescription}
        noindex={!shouldIndex}
        openGraph={{
          url: (process?.env?.baseURL || '') + router.asPath,
          title: title,
          description: description,
          images: image && [image],
        }}
        twitter={{ cardType: 'summary_large_image' }}
        additionalLinkTags={
          faviconUrl
            ? [
                {
                  rel: 'shortcut icon',
                  href: faviconUrl,
                  keyOverride: 'shortcut icon',
                },
                {
                  rel: 'icon',
                  href: faviconUrl,
                  keyOverride: 'icon',
                },
                {
                  rel: 'apple-touch-icon',
                  href: faviconUrl,
                  keyOverride: 'apple-touch-icon',
                },
                {
                  rel: 'apple-touch-icon-precomposed',
                  href: faviconUrl,
                  keyOverride: 'apple-touch-icon-precomposed',
                },
              ]
            : []
        }
        additionalMetaTags={[
          { name: 'apple-itunes-app', content: 'app-id=1526791763' },
          ...(vystemEvent?.name && vystemEvent?.datetime_start && vystemEvent?.settings?.timezone
            ? [
                {
                  property: 'twitter:label1',
                  content: 'Event',
                },
                {
                  name: 'twitter:data1',
                  content: vystemEvent?.name + ' 🗓️',
                },
                {
                  property: 'twitter:label2',
                  content: 'Start',
                },
                {
                  name: 'twitter:data2',
                  content: formatUTCDate(new Date(vystemEvent?.datetime_start), 'dd.MM.yyyy') + ' 🕐',
                },
              ]
            : []),
        ]}
      />
    </>
  );
};
export default VySEO;
