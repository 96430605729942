import { Tooltip, TooltipProps } from '@mui/material';
import React, { CSSProperties, MouseEventHandler, ReactElement, ReactNode } from 'react';

/**
 * this component is build to avoid .toString() conversions in the DOM
 * e.g. <Tooltip title={t('tooltip-registration-accepted').toString()}>
 */

export type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface VyTooltipProps extends Omit<TooltipProps, 'title' | 'onClick' | 'children'> {
  title?: ReactNode;
  style?: CSSProperties;
  placement?: Placement;
  onClick?: Function;
  children: ReactNode;
}

const VyTooltip = (props: VyTooltipProps) => {
  const { title, children, style = {}, placement, onClick } = props;
  return title ? (
    <Tooltip placement={placement} title={title} style={style} onClick={() => onClick?.()} children={children as ReactElement<any, any>} />
  ) : (
    <>{children}</>
  );
};

export default VyTooltip;
