import { useDispatch } from 'react-redux';
import { setCallRingAudio, setCallRingInterval } from '../reduxToolkit/slices/commonSlice';
import { useSelector } from '../reduxToolkit/typedRedux';

export function useSounds() {
  const dispatch = useDispatch();

  const ringInterval = useSelector(({ common }) => common.callRingInterval);
  const ringAudioElement = useSelector(({ common }) => common.callRingAudio);

  function playSound(file: string) {
    try {
      const audio = new Audio(file);
      audio.play();
    } catch (err) {
      console.error('Cannot play audio ', err);
    }
  }
  function _playDing() {
    playSound('/sounds/queue_invite.mp3');
  }

  function playKnock() {
    playSound('/sounds/knocksound.mp3');
  }

  function playChatMessage() {
    playSound('/sounds/intuition-561.mp3');
  }

  function playHangUp() {
    playSound('/sounds/hang_up.wav');
  }

  function playFingersnap() {
    playSound('/sounds/finger_snap.wav');
  }
  function playMeetingJoin() {
    playSound('/sounds/meeting_join.mp3');
  }

  function playPhoneRing(cancelRinging = false) {
    try {
      if (cancelRinging) {
        ringAudioElement?.pause();
        if (ringInterval) {
          clearInterval(ringInterval);
        }
        dispatch(setCallRingInterval(undefined));
        dispatch(setCallRingAudio(undefined));
      } else {
        // Seems to be stacking setIntervals on second call even though cleared before
        const audio = new Audio('/sounds/phone_ring.wav');
        audio.onloadedmetadata = (e) => {
          audio.play();
          dispatch(setCallRingAudio(audio));
          dispatch(
            setCallRingInterval(
              setInterval(() => {
                audio.play().catch((err) => {
                  console.error(err);
                });
              }, audio.duration * 1000)
            )
          );
        };
      }
    } catch (err) {
      console.error('Cannot play audio ', err);
    }
  }

  const playMatchingInvite = () => _playDing();
  const playQueueInvite = () => _playDing();
  const playGuestValidationNotice = () => _playDing();

  return {
    playDing: playQueueInvite,
    playQueueInvite,
    playMatchingInvite,
    playKnock,
    playChatMessage,
    playHangUp,
    playPhoneRing,
    playGuestValidationNotice,
    playFingersnap,
    playMeetingJoin,
    playSound,
  };
}
