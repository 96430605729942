import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { differenceInMilliseconds, format, min } from 'date-fns';
import { de } from 'date-fns/locale';
import * as _ from 'lodash';
import { AttendeeMessage, EventPollQuestion, PageData, Slot } from '../interfaces/interfaces';

export async function getFingerprint() {
  const fpPromise = await FingerprintJS.load();
  const fpResult = await fpPromise.get();
  return fpResult.visitorId;
}

export function delete_cookie(name: any, path?: any, domain?: any) {
  function getAppCookie(name: string) {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  }

  if (getAppCookie(name)) {
    document.cookie = `${name}=${path ? `;path=${path}` : ''}${domain ? `;domain=${domain}` : ''};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}

export function deleteAllCookies() {
  const cookies = ['sde.token', 'sde.mdata', 'de.catok', 'NEXT_LOCALE', 'showCreateMessage', 'showPast'];

  for (const cookie of cookies) {
    delete_cookie(cookie);
  }
}

export function getCookie(name: string) {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
}

export function setCookie(name: string, content: string, duration?: number) {
  document.cookie = `${name}=${content};max-age=${duration || 604800}`;
}

export function formatUTCDate(date: Date, formatString = 'dd.MM.yyyy HH:mm') {
  if (date) {
    // return format(addMinutes(date, date.getTimezoneOffset()), formatString);
    return format(date, formatString, { locale: de });
  }
  throw new Error('Date not set!');
}

export interface CreateDisplayLinkProps {
  id: string | undefined | null;
  returnPlaceholder?: boolean;
  width?: number;
  height?: number;
  quality?: number;
  optimized?: boolean;
  isAvatar?: boolean;
}

export const createDisplayLink = (
  id: string | undefined | null,
  returnPlaceholder = true,
  width?: number,
  height?: number,
  quality?: number,
  optimized = true,
  isAvatar = false,
  isDark = false
) => {
  const apiURL = process.env.baseAPIURL + process.env.basePath!;
  if (id === '/logo.png' || id === 'logo.png') return '/logo.png';

  if (optimized && id) {
    return imageKitUrl(id, width, height, quality);
  }

  if (id) {
    return `${apiURL}file/display/${id}${width && height ? `?w=${width}&h=${height}&q=80` : ``}`;
  }
  if (returnPlaceholder && !isAvatar) return '/logo.png';
  if (returnPlaceholder && isAvatar) return isDark ? '/avatar_dark.png' : '/avatar.png';
  return '';
};

export function getEarliestTimeoutDuration(slots: Slot[]) {
  const upcomingSlots = slots.filter((slot) => slot.status === 'upcoming');
  const onlyDates = upcomingSlots && upcomingSlots.map((slot) => new Date(slot.datetime_start));
  const earliestDate = onlyDates && min(onlyDates);
  const diffInMs = differenceInMilliseconds(earliestDate, new Date());
  const MAX_TIMEOUT = 2147483647;
  return Math.min(diffInMs, MAX_TIMEOUT);
}

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

/**
 * this function returns a percentage number about how many values of the objectKeys have already set a value in the object o
 * @param o the object to check
 * @param objectKeys the keys which need to be filled
 */
export interface conditionKey {
  key: string; // key to check in the object
  condition: (v: any) => boolean; // a condition of the value when this is done
  nextStepDescription: string; // a string description which is displayed if this is not yet fullfilled
  skippable?: boolean; // can skip (has to add key to booth model inside skippedSteps object)
}

export function getFilledPercentage(o: any, objectKeys: conditionKey[]): number {
  let filledKeys = 0;
  if (o) {
    for (const objKey of objectKeys) {
      if (objKey.condition(_.get(o, objKey.key)) || _.get(o, `skippedSteps.${objKey.key}`)) filledKeys++;
    }
    return Math.round((filledKeys / objectKeys.length) * 10) * 10;
  } else {
    return 0;
  }
}

/**
 * Create a hexadecimal color based on a string with JavaScript
 * https://stackoverflow.com/a/16348977
 * @param str
 */
export function stringToColor(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

export function numberWithThousandPoint(x?: number) {
  if (x) {
    try {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } catch (e) {
      return x?.toString() || x;
    }
  } else {
    return '0';
  }
}

/**
 * this function returns the readable strings from an HTML string
 * @param html
 */
export function extractHTMLContent(html: string): string {
  return html.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ');
}

export function openWindowWithPost(url: string, data: any) {
  const form = document.createElement('form');
  form.target = '_blank';
  form.method = 'POST';
  form.action = url;
  form.style.display = 'none';

  for (const key in data) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

/**
 * this function is a Fisher Yates shuffle alorithm,
 * it simply loops through the array and swap out items at random positions.
 *
 */
export function shuffleArray(array: any) {
  for (let i = array?.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

/**
 * this function gets a file url as a parameter and return its extension
 * from https://stackoverflow.com/a/47767860
 *
 * @param url
 */
export function get_url_extension(url: string) {
  return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
}

export const imageKitUrl = (imageId: string, width?: number, height?: number, quality?: number) => {
  if (!imageId || imageId === '') return '/logo.png';
  return `https://static.vystem.io/${imageId}?tr=${width ? `w-${width},` : `w-2500,c-at_max,`}${height ? `h-${height},` : ``}${quality ? `q-${quality}` : ``}`;
};

export const imageKitLoader = ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
  return createDisplayLink(src, true, width, undefined, quality);
};

export function getFirstAsCapital(text: string) {
  return text.toUpperCase();
}

export function pollNotifyVisitor(pollRef: EventPollQuestion[], pusherData: EventPollQuestion, pollAnswers: string[]) {
  const ref = pollRef.map((item: EventPollQuestion) => {
    if (item._id === pusherData._id) return item;
  });
  if (!ref[0]?.isActive && pusherData.isActive === true && !pollAnswers.includes(pusherData._id)) {
    return true;
  } else return false;
}

export function roundDownToNearest10(num: number) {
  return Math.floor(num / 10) * 10;
}

export function pagenameBuilder(pagesArray: PageData[], pageid: string) {
  let newName = '';
  const searchedPage = pagesArray.find((e) => e._id == pageid);
  if (pagesArray.filter((sp) => sp.name.replace(/\s/g, '') == searchedPage?.name.replace(/\s/g, '')).length > 1) {
    newName = searchedPage?.name + ` ${searchedPage?.visibility?.visibleApp ? '[APP]' : '[WEB]'}`;
  } else {
    newName = searchedPage?.name || '';
  }
  return newName;
}

export function richTextContentFormat(content?: string) {
  const olStyle = 'margin-block-start: 1em;margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px; padding-inline-start: 40px;';
  const ulStyle =
    'display: block; list-style-type: disc;  margin-block-start: 1em;  margin-block-end: 1em;margin-inline-start: 0px; margin-inline-end: 0px; padding-inline-start: 40px;';

  return content?.replaceAll('<ol>', `<ol style="${olStyle}">`).replaceAll('<ul>', `<ul style="${ulStyle}">`) || '';
}

function _idToTimestamp(id: string) {
  const timestamp = id.toString().substring(0, 8);
  return new Date(parseInt(timestamp, 16) * 1000);
}

export function conversationKeyFromMessage(message: AttendeeMessage) {
  const senderTimestamp = _idToTimestamp(message.sender);
  const receiverTimestamp = _idToTimestamp(message.recepient);
  if (senderTimestamp > receiverTimestamp) return `${message.sender}${message.recepient}`;
  return `${message.recepient}${message.sender}`;
}

export function stringIsNumber(text: string) {
  var reg = /^\d+$/;
  return reg.test(text);
}

/**
 * Returns differnce between two objects
 */
export function objectDiff<T extends object>(obj1: T, obj2: T): Partial<T> {
  const diff: Partial<T> = {};

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        diff[key] = obj2[key];
      }
    }
  }

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key) && !(key in obj1)) {
      diff[key] = obj2[key];
    }
  }

  return diff;
}
