import Script from 'next/script';
import { useEffect, useState } from 'react';

export const CookieServices = () => {
  const [hasMarketingConsent, setHasMarketingConsent] = useState(false);

  function checkCookieSettings() {
    if (window && (window as any).Cookiebot) {
      const bot = (window as any).Cookiebot;
      setHasMarketingConsent(bot?.consent?.marketing);
    }
  }

  useEffect(() => {
    if (!window) return;

    window.addEventListener('CookiebotOnLoad', checkCookieSettings);

    /* window.addEventListener('CookiebotOnAccept', (data) => {
      console.log('CookiebotOnAccept');
      console.log(data);
    });

    window.addEventListener('CookiebotOnDecline', (data) => {
      console.log('CookiebotOnDecline');
      console.log(data);
    }); */

    //TODO get rid of listeners
    return function cleanup() {
      window?.removeEventListener('CookiebotOnLoad', checkCookieSettings);
    };
  }, []);

  return (
    <>
      {hasMarketingConsent && (
        <>
          <Script async src="https://www.googletagmanager.com/gtag/js" />
          <Script
            id="gtag-data-layer"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());`,
            }}
          />
          <Script
            id="gtag-data"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-KBTQB27');`,
            }}
          />
          <Script
            id="fbtracking"
            dangerouslySetInnerHTML={{
              __html: `
                    !(function (f, b, e, v, n, t, s) {
                    if (f.fbq) return;
                    n = f.fbq = function () {
                    n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
                };
                    if (!f._fbq) f._fbq = n;
                    n.push = n;
                    n.loaded = !0;
                    n.version = "2.0";
                    n.queue = [];
                    t = b.createElement(e);
                    t.async = !0;
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s);
                })(
                    window,
                    document,
                    "script",
                    "https://connect.facebook.net/en_US/fbevents.js"
                    );
                    fbq("init", "534514104136701");
                    fbq("track", "PageView");
                    `,
            }}
          />
        </>
      )}
    </>
  );
};
