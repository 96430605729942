import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';
import { VyLoader } from '../components/common/VyLoader';
import { useLanguage } from '../hooks/useLanguage';

export const AutoLanguageWrapper = (props: { children?: ReactNode }) => {
  const { checkNavigatorLanguage, isCheckingLanguage } = useLanguage();

  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;
    checkNavigatorLanguage();
  }, [router.isReady]);

  if (isCheckingLanguage) return <VyLoader solidBackground />;

  return <>{props.children}</>;
};
